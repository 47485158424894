import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import "./App.css";
import Footer from "./components/Footer";
import Gallery from "./pages/Gallery";
import OurStory from "./pages/OurStory";
import HousingPlan from "./pages/HousingPlan";
import ContactUs from "./pages/ContactUs";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <Router>
      <Helmet>
        <title>Hiks Homes</title>
        <meta
          name="description"
          content="The idea of HIKS HOMES has been crafted to deliver the Overseas Pakistani’s an opportunity to own the real estate with the ease of mind and complete independency HIKS HOMES brings you the solution which secures and adds value to your real estate with future property management plan."
        />
      </Helmet>
      <div className="App">
        {/* Navbar */}
        <Navbar />

        {/* Routing */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/our-story" element={<OurStory />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/housing-plan" element={<HousingPlan />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
        <Footer />
      </div>
    <ToastContainer
      position="bottom-right"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
    />
    </Router>
  );
}

export default App;
