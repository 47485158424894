import React, { useState, useEffect } from "react";
import logo from "../assets/Logo/logo.svg";
import { NavLink, useNavigate } from "react-router-dom";

const Navbar = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const visible = prevScrollPos > currentScrollPos || currentScrollPos < 50;

      setPrevScrollPos(currentScrollPos);
      setVisible(visible);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  return (
    <>
      <nav
        className={`z-50 bg-background/80  fixed w-full transition-all duration-700 ${
          visible ? "md:top-0 top-0" : "md:-top-28 top-0"
        }`}
      >
        <div className="container mx-auto px-4 py-5 flex justify-between items-center">
          {/* Logo */}
          <div className="flex items-center">
            <img
              src={logo}
              alt="Company Logo"
              className="h-10 w-auto md:h-14"
            />
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-10">
            <NavLink
              to="/"
              className={({ isActive }) =>
                `text-xs font-body leading-xs tracking-wide ${
                  isActive ? "text-textHover" : "text-white"
                } hover:text-textHover`
              }
              onClick={(e) => {
                e.preventDefault();
                window.location.href = "/";
              }}
            >
              HOME
            </NavLink>
            <NavLink
              to="/our-story"
              className={({ isActive }) =>
                `text-xs font-body leading-xs tracking-wide ${
                  isActive ? "text-textHover" : "text-white"
                } hover:text-textHover`
              }
              onClick={(e) => {
                e.preventDefault();
                window.location.href = "/our-story";
              }}
            >
              OUR STORY
            </NavLink>
            <NavLink
              to="/gallery"
              className={({ isActive }) =>
                `text-xs font-body leading-xs tracking-wide ${
                  isActive ? "text-textHover" : "text-white"
                } hover:text-textHover`
              }
              onClick={(e) => {
                e.preventDefault();
                window.location.href = "/gallery";
              }}
            >
              GALLERY
            </NavLink>
            <NavLink
              to="housing-plan"
              className={({ isActive }) =>
                `text-xs font-body leading-xs tracking-wide ${
                  isActive ? "text-textHover" : "text-white"
                } hover:text-textHover`
              }
              onClick={(e) => {
                e.preventDefault();
                window.location.href = "/housing-plan";
              }}
            >
              HOUSING PLAN
            </NavLink>
          </div>

          {/* Contact Button */}
          <div className="hidden md:flex">
            <NavLink
              to="/contact-us"
              className="bg-textHover text-white hover:text-textHover px-6 py-3 rounded-lg shadow-md hover:bg-white transition duration-300"
              onClick={(e) => {
                e.preventDefault();
                window.location.href = "/contact-us";
              }}
            >
              CONTACT
            </NavLink>
          </div>

          {/* Mobile Menu Button */}
          <button
            className="md:hidden flex items-center px-4 py-2 text-white focus:outline-none"
            onClick={() => setDrawerOpen(!drawerOpen)}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
          </button>
        </div>
      </nav>

      {/* Mobile Drawer */}
      <div
        className={`md:hidden fixed top-[5rem] left-0 right-0 bg-background/80 overflow-hidden transition-all duration-900 ease-in-out z-50 border-b-2 border-white ${
          drawerOpen ? "max-h-screen" : "max-h-0"
        }`}
      >
        <div className="w-full p-6 text-center">
          <div className="flex flex-col space-y-4">
            <NavLink
              to="/"
              className={({ isActive }) =>
                `text-xs font-body leading-xs tracking-wide ${
                  isActive ? "text-textHover" : "text-white"
                } hover:text-textHover`
              }
              onClick={(e) => {
                setDrawerOpen(false);
                e.preventDefault();
                window.location.href = "/";
              }}
            >
              HOME
            </NavLink>
            <NavLink
              to="/our-story"
              className={({ isActive }) =>
                `text-xs font-body leading-xs tracking-wide ${
                  isActive ? "text-textHover" : "text-white"
                } hover:text-textHover`
              }
              onClick={(e) => {
                setDrawerOpen(false);
                e.preventDefault();
                window.location.href = "/our-story";
              }}
            >
              OUR STORY
            </NavLink>
            <NavLink
              to="/gallery"
              className={({ isActive }) =>
                `text-xs font-body leading-xs tracking-wide ${
                  isActive ? "text-textHover" : "text-white"
                } hover:text-textHover`
              }
              onClick={(e) => {
                setDrawerOpen(false);
                e.preventDefault();
                window.location.href = "/gallery";
              }}
            >
              GALLERY
            </NavLink>
            <NavLink
              to="housing-plan"
              className={({ isActive }) =>
                `text-xs font-body leading-xs tracking-wide ${
                  isActive ? "text-textHover" : "text-white"
                } hover:text-textHover`
              }
              onClick={(e) => {
                setDrawerOpen(false);
                e.preventDefault();
                window.location.href = "/housing-plan";
              }}
            >
              HOUSING PLAN
            </NavLink>
            <NavLink
              to="/contact-us"
              className={({ isActive }) =>
                `text-xs font-body leading-xs tracking-wide ${
                  isActive ? "text-textHover" : "text-white"
                } hover:text-textHover`
              }
              onClick={(e) => {
                setDrawerOpen(false);
                e.preventDefault();
                window.location.href = "/contact-us";
              }}
            >
              CONTACT
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
