import React, { useState, useEffect } from "react";
import "react-image-lightbox/style.css"; // Import the lightbox CSS
import Lightbox from "react-image-lightbox";
import image1 from "../assets/Images/galleryImages/House1-Exterior-Day.jpg";
import image2 from "../assets/Images/galleryImages/House1-Exterior-Night.jpg";
import image3 from "../assets/Images/galleryImages/SR1.jpg";
import image4 from "../assets/Images/galleryImages/SR2.jpg";
import image5 from "../assets/Images/galleryImages/SR3.jpg";
import image6 from "../assets/Images/galleryImages/SR4.jpg";
import image7 from "../assets/Images/galleryImages/sr5.jpg";
import image8 from "../assets/Images/galleryImages/SR6.jpg";
import image9 from "../assets/Images/galleryImages/SR7.jpg";
import image10 from "../assets/Images/galleryImages/SR8.jpg";
import image11 from "../assets/Images/galleryImages/SR9.jpg";
import image12 from "../assets/Images/galleryImages/SR10.png";
import image13 from "../assets/Images/galleryImages/SR11.png";
import image14 from "../assets/Images/galleryImages/SR12.jpg";
import image15 from "../assets/Images/galleryImages/SR13.jpg";

const images = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
];

const ProjectGallery = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [lightboxLoading, setLightboxLoading] = useState(false);

  const preloadImages = () => {
    let loadedCount = 0;
    images.forEach((image) => {
      const img = new Image();
      img.src = image;
      img.onload = () => {
        loadedCount++;
        if (loadedCount === images.length) {
          setLoading(false);
        }
      };
    });
  };

  useEffect(() => {
    preloadImages();
  }, []);

  const handleLightboxOpen = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
    setLightboxLoading(true);
  };

  const handleImageLoad = () => {
    setLightboxLoading(false);
  };

  return (
    <div className="bg-light-gray py-4 px-4">
      <div className="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-3 lg:gap-4">
        {images.map((image, index) => (
          <div
            key={index}
            className="rounded overflow-hidden shadow-lg cursor-pointer"
            onClick={() => handleLightboxOpen(index)}
          >
            <img
              src={image}
              alt={`Gallery Image ${index + 1}`}
              className="w-full h-64 object-cover"
            />
          </div>
        ))}
      </div>

      {isOpen && (
        <>
          {lightboxLoading && (
            <div className="loading-indicator">Loading...</div>
          )}
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setIsOpen(false)}
            onImageLoad={handleImageLoad} // Use this to detect when the image is fully loaded
            onMovePrevRequest={() => {
              setPhotoIndex((photoIndex + images.length - 1) % images.length);
              setLightboxLoading(true);
            }}
            onMoveNextRequest={() => {
              setPhotoIndex((photoIndex + 1) % images.length);
              setLightboxLoading(true);
            }}
            imagePadding={20}
            wrapperClassName="lightbox-image-wrapper"
          />
        </>
      )}
    </div>
  );
};

export default ProjectGallery;
