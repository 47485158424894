import React from "react";
import CustomHeading from "../components/CustomHeading";
import ProjectGallery from "../components/ProjectGallery";
import GetInTouch from "../components/GetInTouch";

const Gallery = () => {
  return (
    <div>
      <CustomHeading
        heading={"Gallery"}
        description={"A snapshot into our past developments"}
      />
      <ProjectGallery />
      <div className="py-10">
        <GetInTouch />
      </div>
    </div>
  );
};

export default Gallery;
