import React from "react";
const PlanModal = ({
  planImage,
  videoSrc,
  planName,
  onClose,
  total,
  advance,
  installment,
  afterNinMonths,
  Confirmation,
}) => {
  const isVideo = Boolean(videoSrc);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-3xl h-screen max-h-screen overflow-y-auto">
        {/* Modal Header */}
        <div className="flex justify-between items-center p-6 border-b border-gray-200 sticky top-0 bg-white z-10">
          <div></div>
          <h3 className="text-2xl font-bold text-gray-800 font-heading">
            {planName}
          </h3>
          <button
            className="text-gray-600 hover:text-gray-900 focus:outline-none text-3xl"
            onClick={onClose}
          >
            ×
          </button>
        </div>

        {/* Modal Body */}
        <div className="p-6 space-y-6">
          {/* Plan Image or Video */}
          {isVideo ? (
            <div className="w-full rounded-md overflow-hidden">
              <video
                src={videoSrc}
                className="w-full object-cover rounded-md"
                controls
                autoPlay
                muted
                loop
                playsInline
              />
            </div>
          ) : (
            <img
              src={planImage}
              alt="House Plan"
              className="w-full object-cover rounded-md"
            />
          )}

          {/* Total Price Section */}
          <div className="bg-textHover text-white text-center py-4 rounded-md">
            <h3 className="text-2xl font-bold">TOTAL PRICE</h3>
            <p className="text-xl font-semibold">{total}</p>
          </div>

          {/* Payment Table */}
          <div className="overflow-x-auto">
            <table className="min-w-full table-auto bg-white border-collapse border border-gray-200 rounded-lg">
              <thead>
                <tr className="bg-textHover text-white">
                  <th className="px-6 py-3 text-left text-sm font-medium uppercase tracking-wider">
                    Milestone
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-medium uppercase tracking-wider">
                    Payment
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-gray-50 border-b">
                  <td className="px-6 py-4 text-sm font-medium text-gray-800">
                    Advance (35%)
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-600">{advance}</td>
                </tr>
                <tr className="bg-white">
                  <td className="px-6 py-4 text-sm font-medium text-gray-800">
                    Confirmation
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-600">
                    {Confirmation}
                  </td>
                </tr>
                <tr className="bg-white border-b">
                  <td className="px-6 py-4 text-sm font-medium text-gray-800">
                    Monthly Installment
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-600">
                    {installment}
                  </td>
                </tr>
                <tr className="bg-gray-50 border-b">
                  <td className="px-6 py-4 text-sm font-medium text-gray-800">
                    After 9 Months
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-600">
                    {afterNinMonths}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Modal Footer */}
        <div className="flex justify-end p-6 border-t border-gray-200">
          <button
            className="bg-textHover text-white px-6 py-2 rounded-md hover:bg-white hover:text-textHover border border-textHover transition-colors"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlanModal;