import React from "react";
import ArrowLabel from "../components/ArrowLabel";
import ImageSlideshow from "../components/ImageSlideshow";
import House1ExteriorDay from "../assets/Images/galleryImages/House1-Exterior-Day.jpg";
import House1ExteriorNight from "../assets/Images/galleryImages/House1-Exterior-Night.jpg";
import House1Interior1 from "../assets/Images/House1-Interior1.jpg";
import House1Interior2 from "../assets/Images/House1-Interior2.jpg";
import LargeBuilding from "../assets/Images/LargeBuilding.jpg";
import VideoBackground from "../components/VideoBackground";
import AnimatedScrollingText from "../components/AnimatedScrollingText";
import LocationMap from "../components/LocationMap";
import Service from "../components/Services";

function Home() {
  const images = [
    House1ExteriorDay,
    House1ExteriorNight,
    House1Interior1,
    House1Interior2,
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-linen via-background to-linen">
      <div className="bg-background md:pt-0 pt-20">
        <VideoBackground />
      </div>
      <div className="relative">
        <div className="absolute inset-0  opacity-60"></div>
        <div className="relative pt-0 md:pt-16 pb-10 md:pb-16 px-4 sm:px-6 lg:px-8">
          <div className="max-w-6xl mx-auto">
            <div className="text-center mb-16">
              <h1 className="text-white text-5xl sm:text-6xl md:text-7xl font-heading mb-2 animate-fade-in-down">
                Our Vision
              </h1>
              <p className="text-white text-xl sm:text-2xl md:text-3xl font-body max-w-3xl mx-auto animate-fade-in-up">
                Integrity Above All
              </p>
              <p className="text-gray-300 font-body text-sm sm:text-base md:text-lg mb-8 max-w-2xl mx-auto animate-fade-in-up italic">
                Nothing can get in the way of honesty. In the real estate market
                many have come and gone. What still stands today and will stand
                forever is honesty.
              </p>
            </div>

            <div className="grid md:grid-cols-2 gap-12 items-center">
              <div className="space-y-6 animate-fade-in-left">
                <h2 className="text-xl sm:text-2xl font-bold text-white font-heading">
                  Your Gateway to Real Estate Satisfaction
                </h2>
                <div className="max-w-3xl mx-auto px-4 sm:px-6 text-white">
                  {/* <p className="mb-4 text-justify font-body text-sm">
                    <strong className="text-textHover">Hiks HOMES </strong>{" "}
                    gives you the satisfaction, its a name of the legacy of four
                    well reputed friends in real estate industry who got
                    together to deliver the product people have been long
                    waiting for. The idea of{" "}
                    <strong className="text-textHover">Hiks HOMES </strong> has
                    been crafted to deliver the Overseas Pakistani’s an
                    opportunity to own the real estate with the ease of mind and
                    complete independency{" "}
                    <strong className="text-textHover">Hiks HOMES </strong>{" "}
                    brings you the solution which secures and adds value to your
                    real estate with future property management plan. The
                    security of your investment and building a dream home is all
                    available in one plan. From A class location and top grade
                    materials / energy sufficient solutions, Hiks has made it
                    simple, easy and convenient. No more complicated contracts,
                    conditions and procedures. Get your land transferred to your
                    name on 60% of your payment plan. Complete your 18 month
                    payment plan and receive the key to your villa/ town home.{" "}
                  </p> */}
                  {/* <p className="mb-4 text-justify font-body text-sm">
                    <strong className="text-textHover">Hiks HOMES </strong>has
                    selected one of the most premium location to provide the new
                    phenomenon of excellence. The team has delivered Land mark
                    projects in the twin cities and have gained recognition as a
                    hallmark of construction, commercial and residential
                    projects. The land has already been acquired and developed
                    with carpeted roads, sanitation and sewerage system.In
                    record time, the rise of HIKS HOMES because of ALLAH
                    ALMIGHTY’s blessings. For this unprecedented Success, we owe
                    heartfelt gratitude to our clients, partners, associates and
                    friends. Its because of continued patronage, support & trust
                    that makes{" "}
                    <strong className="text-textHover">Hiks HOMES </strong>a
                    symbol of undeniable excellence.
                  </p> */}
                  <ul className="list-disc pl-5">
                    <li className="mb-2 text-justify font-body text-sm">
                      The goal of HIKS HOMES is to give Pakistanis living abroad
                      a simple way to become property owners with total freedom
                      and peace of mind. Through management plans that are
                      focused on the future, we provide solutions that protect
                      and increase the value of your property.
                    </li>
                    <li className="mb-2 text-justify font-body text-sm">
                      The goal of HIKS HOMES is to give Pakistanis living abroad
                      a simple way to become property owners with total freedom
                      and peace of mind. Through management plans that are
                      focused on the future, we provide solutions that protect
                      and increase the value of your property.
                    </li>
                    <li className="mb-2 text-justify font-body text-sms">
                      HIKS HOMES offers a simplified and hassle-free experience;
                      Say goodbye to complicated contracts, terms, and
                      procedures.
                    </li>
                  </ul>
                </div>
                <div className="mt-8">
                  <ArrowLabel
                    direction="right"
                    label="Explore Our Payment Plans"
                    color="white"
                    className="text-lg hover:translate-x-2 transition-transform duration-300"
                    href="housing-plan"
                  />
                </div>
              </div>
              <div className="relative animate-fade-in-right overflow-hidden rounded-lg">
                <div className="overflow-hidden shadow-2xl">
                  <ImageSlideshow images={images} />
                </div>
                <div className="absolute -top-4 -right-3 w-24 h-24 bg-white/10 rounded-full flex items-center justify-center transform rotate-[45deg] shadow-2xl">
                  <span className="text-textHover font-bold text-lg">
                    Trusted
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <AnimatedScrollingText image={LargeBuilding} /> */}
      <Service />
      <LocationMap />
    </div>
  );
}

export default Home;
