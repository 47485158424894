import React from "react";
import {
  FaHome,
  FaClipboardCheck,
  FaCogs,
  FaSolarPanel,
  FaWater,
  FaBuilding,
} from "react-icons/fa"; // Importing icons
import DesignImage from "../assets/Images/bedroomInterior.jpg";
import FloorPlanImage from "../assets/Images/16Marla.png";
import ConstructionImage from "../assets/Images/underConstruction.png";
import VendorsImage from "../assets/Images/material.png";
import AddOnsImage from "../assets/Images/solarImage.png";
import RentImage from "../assets/Images/homeInterior.jpg";

const services = [
  {
    icon: <FaHome size={45} />,
    label: "Design your Home",
    image: DesignImage,
  },
  {
    icon: <FaClipboardCheck size={45} />,
    label: "Choose your own floor plan",
    image: FloorPlanImage,
  },
  // {
  //   icon: <FaCogs size={45} />,
  //   label: "Stay updated with the process of construction on the web portal",
  //   image: ConstructionImage,
  // },
  // {
  //   icon: <FaBuilding size={45} />,
  //   label:
  //     "List for all the vendors supplying materials available on the web portal",
  //   image: VendorsImage,
  // },
  {
    icon: <FaSolarPanel size={45} />,
    label:
      "Choose from add-ons to live a hassle-free life, Zero Bills Guaranteed ,Solar Energy Solution, Private Water Boring, LPG Cylinder system",
    image: AddOnsImage,
  },
  {
    icon: <FaWater size={45} />,
    label:
      "Let us manage your property in the future and turn this investment into profits (Rent/Airbnb)",
    image: RentImage,
  },
];

function Service() {
  return (
    <div className="p-4 rounded-lg shadow-lg my-12 text-white">
      <h2 className="text-3xl font-bold text-center mb-8">Our Services</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {services.map((service, index) => (
          <div
            key={index}
            className="flex flex-col items-center justify-center bg-white text-gray-800 p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 transform hover:-translate-y-1 hover:scale-105"
          >
            <div className="w-3/4 flex flex-col items-center justify-center">
              {/* Icon Overlay */}
              <div className="bg-white p-2 rounded-full text-textHover text-3xl">
                {service.icon}
              </div>
              {/* Image */}
              <img
                src={service.image}
                alt={service.label}
                className="w-3/4 object-cover rounded-md mb-4"
              />
            </div>
            <div className="text-center">
              <h3 className="text-xl font-semibold mb-2">{service.label}</h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Service;
