import React from "react";
import { Link, useNavigate } from "react-router-dom";

import logowithname from "../assets/Logo/logo.svg";

function Footer() {
  return (
    <div class="flex flex-col bg-background justify-center items-center pt-20 pb-10">
      <img src={logowithname} alt="Logo with Name" class="w-auto h-40" />
      <div class="font-bold text-white text-md mb-2 mt-4">Contact us</div>
      <div className="flex flex-col items-center text-fadedText text-m space-y-4 md:space-y-6">
        <div className="flex justify-center space-x-6">
          <a href="tel:0512358726" className="hover:underline">
            051-2358726
          </a>
          <span className="hidden md:inline">|</span>
          <a href="tel:03705044794" className="hover:underline">
            03705044794
          </a>
        </div>

        <div className="flex justify-center">
          <a href="tel:03321633339" className="hover:underline">
            03321633339
          </a>
        </div>

        <div className="flex justify-center">
          <a href="mailto:info@hikshomes.com" className="hover:underline">
            info@hikshomes.com
          </a>
        </div>
      </div>

      <div class="text-fadedText text-m mt-2 font-semibold ">
        Infront of D-18 Roots Millennium School{" "}
      </div>
      <div class="text-white text-m mt-2">
        © Hiks Homes 2018.{" "}
        <button
          onClick={() => window.open("https://innspirovatetech.com/", "_blank")}
          class="hover:underline"
        >
          Website by Innspirovate Tech
        </button>
      </div>
    </div>
  );
}

export default Footer;
