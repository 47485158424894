import React from "react";
import SearchHome from "../assets/Images/searchHome.jpg";
import ReserveHome from "../assets/Images/reserveHome.jpg";
import IDImage from "../assets/Images/id.png";
import NewHome from "../assets/Images/newHome.jpg";
import Contract from "../assets/Images/contract.jpg";
import Enjoy from "../assets/Images/happyNewHome.jpg";

const steps = [
  {
    id: 1,
    title: "Step one",
    subtitle: "Research",
    description:
      "Search from our Plans, where you can choose from the options. Once you find the plan that suits you, get in touch to book an appointment to view a show home",
    linkText: "Housing Plan",
    linkUrl: "/housing-plan",
    imageSrc: SearchHome,
  },
  {
    id: 2,
    title: "Step two",
    subtitle: "Reserve",
    description:
      "To reserve your property, 35% of downpayment is required , and get your plot transfered on 50% of possession along with the house on 100%",
    linkText: null,
    linkUrl: null,
    imageSrc: ReserveHome,
  },
  {
    id: 3,
    title: "Step three",
    subtitle: "Documentation",
    description:
      "As part of the legal requirements in Pakistan, each buyer must be named on the sale agreement and provide two forms of identification:",
    list: [
      "Photo ID (CNIC or Passport)",
      "Proof of Address (Utility bill, Bank Statement, or Rent Agreement)",
    ],
    linkText: null,
    linkUrl: null,
    imageSrc: IDImage,
  },
  // {
  //   id: 4,
  //   title: "Step four",
  //   subtitle: "Instruct Solicitors",
  //   description:
  //     "Submit your lawyer's details to the seller. You can use your own lawyer or choose one from our recommended panel of solicitors to ensure a smooth transaction. It's advised to have a real estate lawyer review your documents and agreements.",
  //   linkText: null,
  //   linkUrl: null,
  //   imageSrc: NewHome,
  // },
  {
    id: 4,
    title: "Step four",
    subtitle: "Exchange of Contracts",
    description:
      "After reserving the property, you are required to sign the sale agreement within 30 days. A 10% deposit is necessary upon signing the sale agreement, which will be deducted from your reservation fee. Please ensure that all documents are thoroughly verified before signing.",
    linkText: null,
    linkUrl: null,
    imageSrc: Contract,
  },
  {
    id: 6,
    title: "Step five",
    subtitle: "Enjoy",
    description:
      "Once all the legal work is done and the sale is complete, enjoy your new home and make it your own!",
    linkText: null,
    linkUrl: null,
    imageSrc: Enjoy,
  },
];

const BuyingProcess = () => {
  return (
    <section className="bg-background text-white py-10 px-6">
      <h2 className="text-center text-4xl font-heading mb-8">Buying Process</h2>

      <div className="flex flex-col gap-16">
        {steps.map((step) => (
          <div
            key={step.id}
            className="flex flex-col lg:flex-row items-center lg:w-1/2 mx-auto"
          >
            <img
              src={step.imageSrc}
              alt={step.title}
              className="w-full lg:w-1/2 object-cover rounded-lg"
            />
            <div className="lg:ml-8 mt-6 lg:mt-0 text-center lg:text-left">
              <h3 className="text-2xl font-heading text-textHover mb-4">
                {step.title}
              </h3>
              <h4 className="text-xl font-bold mb-2">{step.subtitle}</h4>
              <p className="text-fadedText">{step.description}</p>

              {step.list && (
                <ul className="list-disc list-inside mt-2 text-fadedText">
                  {step.list.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              )}
              {step.linkText && (
                <p className="mt-4">
                  <a
                    href={step.linkUrl}
                    className="text-textHover hover:underline"
                  >
                    {step.linkText}
                  </a>
                </p>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
export default BuyingProcess;
