import React from "react";
import CustomHeading from "../components/CustomHeading";
import ImageTextAlternating from "../components/ImageTextAleternating";
import GetInTouch from "../components/GetInTouch";
import BuyingProcess from "../components/BuyingProcess";

const OurStory = () => {
  return (
    <div>
      <CustomHeading
        heading={"Our Story"}
        description={"Homes not just built, but crafted."}
      />
      <ImageTextAlternating />
      <BuyingProcess />
      <GetInTouch />
    </div>
  );
};

export default OurStory;
