import React from "react";
import Home from "../assets/Images/home1.jpg";
import HomeInterior from "../assets/Images/homeInterior.jpg";
import ArrowLabel from "./ArrowLabel";

const ImageTextAlternating = () => {
  return (
    <div className="text-left bg-[#f5efe3] py-8 px-4 md:px-16">
      {/* Image Left, Text Right */}
      <div className="flex flex-col md:flex-row items-center md:items-start space-y-6 md:space-y-0 md:space-x-6">
        <div className="w-full md:w-1/2">
          <img
            src={Home}
            alt="House Exterior"
            className="w-full h-auto rounded-md object-cover"
          />
        </div>
        <div className="w-full self-center md:w-1/2 ">
          <h2 className="text-3xl font-heading md:text-4xl font-bold mb-4 text-[#473c34]">
            A Decade Of Reputation For Traditional Quality
          </h2>
          <p className="text-black font-body text-base md:text-base mb-4">
            Hiks Homes is based in the Islamabad, the Beautiful Capital Pakistan
            and from there we have been creating high-quality homes and
            developments in the City for over a decade.
            <br />
            Our work includes New Commercial Developments and New Homes.
          </p>
          <p className="text-black font-body text-base md:text-base mb-4">
            {/* <br />
            <strong>Crafed With Care</strong>
            <br />
            Inheriting a legacy from our forefather , our dedication to ...... */}
          </p>
          {/* <ArrowLabel
            href="Land"
            label="Land"
            color="black"
            className="text-base hover:translate-x-2 transition-transform duration-300"
          /> */}
        </div>
      </div>

      {/* Text Left, Image Right */}
      <div className="flex flex-col md:flex-row-reverse items-center md:items-start space-y-6 md:space-y-0 md:space-x-6 mt-12">
        <div className="w-full md:w-1/2">
          <img
            src={HomeInterior}
            alt="House Exterior"
            className="w-full h-auto rounded-md object-cover"
          />
        </div>
        <div className="w-full self-center md:w-1/2">
          <h2 className="text-3xl font-heading md:text-4xl font-bold mb-4 text-[#473c34]">
            Crafted with Care
          </h2>
          <p className="text-black font-body text-base md:text-base mb-4">
            Inheriting the legacy from our forefathers our dedication to and get
            you out of possession.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ImageTextAlternating;
