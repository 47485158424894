import React from "react";
import ArrowLabel from "./ArrowLabel";

function ImageWithHoverEffect({
  image,
  title,
  subTitle,
  details,
  price,
  overlayTitle,
  overlaySubTitle,
  overlayIcon,
  onClick,
}) {
  return (
    <div className="flex flex-col relative items-center" onClick={onClick}>
      {/* Image Container */}
      <div className="relative w-2/3 mb-4 overflow-hidden group cursor-pointer">
        {/* Original Image */}
        <img
          src={image}
          alt={title}
          className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
        />

        {/* Transparent Overlay */}
        <div className="absolute inset-0 bg-black bg-opacity-80 flex flex-col justify-center items-center opacity-0 transition-opacity duration-700 group-hover:opacity-100">
          {/* Optional Overlay Icon (like feather in your image) */}
          {overlayIcon && (
            <img
              src={overlayIcon}
              alt="Icon"
              className="mb-4 w-[100px] h-[100px] object-contain"
            />
          )}

          {/* Overlay Title */}
          {overlayTitle && (
            <h3 className="text-white text-3xl font-bold mb-2 font-heading">
              {overlayTitle}
            </h3>
          )}

          {/* Overlay Subtitle */}
          {overlaySubTitle && (
            <p className="text-white text-xl font-light font-body">
              {overlaySubTitle}
            </p>
          )}
        </div>
      </div>
      {/* Optional: Add any other content below the image */}
      {/* If you want, you can uncomment the code below to display titles and
      details */}
      {/* {title && (
        <div className="w-full flex flex-col justify-start items-start text-center">
          <ArrowLabel label={title} color={"white"} />
          <div className="w-full border-t border-white my-4"></div>
          <p className="font-bold text-left">{subTitle}</p>
          <p className="text-left">{details}</p>
          {price && (
            <p className="mt-2 font-bold text-left text-textHover">
              Prices from {price}
            </p>
          )}
        </div>
      )} */}
    </div>
  );
}

export default ImageWithHoverEffect;
