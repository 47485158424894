import React from "react";
import backgroundVideo from "../assets/Videos/BackgroundVideo.mp4";

function VideoBackground() {
  return (
    <div className="relative w-full h-auto md:h-screen overflow-hidden">
      <video
        autoPlay
        loop
        muted
        className="w-full h-auto object-contain"
        style={{
          pointerEvents: "none",
        }}
      >
        <source src={backgroundVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

export default VideoBackground;
