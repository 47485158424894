import React, { useState } from "react";
import CustomHeading from "../components/CustomHeading";
import Plan16Marla from "../assets/Images/16Marla.png";
import Plan5Marla from "../assets/Images/5Marla.png";
import Plan5MarlaVideo from "../assets/Videos/5marlaPlan.mp4";
import Plan3 from "../assets/Images/3Plan.png";
import ImageWithHoverEffect from "../components/ImageWithHoverEffect";
import VideoWithHoverEffect from "../components/VideoWithHoverEffect";
import ArrowLabel from "../components/ArrowLabel";
import PlanModal from "../components/PlanModal";

const plansData = [
  {
    id: 1,
    planName: "4.16 MARLA",
    planImage: Plan3,
    total: "1,50,00,000/-",
    advance: "Rs. 5250,000/-",
    installment: "Rs 3,19,445/-",
    afterNinMonths: "Rs 20,00,000/-",
    Confirmation: "Rs 20,00,000/-",
  },
  {
    id: 2,
    planName: "5 MARLA",
    planImage: Plan5Marla,
    videoSrc: Plan5MarlaVideo,
    total: "18,500,000  PKR",
    advance: "5,000,000 PKR",
    installment: "1,222,222 PKR",
    afterNinMonths: "5,000,000 PKR",
    Confirmation: "5,000,000 PKR",
  },
  {
    id: 3,
    planName: "10 MARLA",
    planImage: Plan16Marla,
    total: "37,000,000 PKR",
    advance: "5,000,000 PKR",
    Confirmation: "5,000,000 PKR",
    installment: "527,778 PKR",
    afterNinMonths: "5,000,000 PKR",
  },
];

const HousingPlan = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  const openModal = (plan) => {
    setModalData(plan);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="container mx-auto bg-gray-50">
      <CustomHeading
        heading={"Plan"}
        description="Nothing can get in the way of honesty. In the real estate market, many have come and gone. What still stands today and will stand forever is honesty"
      />

      <section className="p-4 sm:p-6">
        <h2 className="text-3xl sm:text-4xl font-bold text-center mb-8 font-heading">
          Plans We Offer
        </h2>

        {/* Plan 4.16 Marla */}
        <section className="flex flex-col md:flex-row md:space-x-6 space-y-6 md:space-y-0 p-4">
          <div className="w-full md:w-1/2 flex flex-col items-start text-left justify-center">
            <ul className="mb-3">
              <li>Design your Home</li>
              <li>Choose your own floor plan</li>
              <li>
                Stay updated with the process of construction on the web portal
              </li>
              <li>
                List of all the vendors supplying materials will be available on
                the web portal
              </li>
            </ul>
            <ArrowLabel
              label="4.16 Marla "
              color="black"
              onClick={() => openModal(plansData[0])}
            />
          </div>

          <div className="w-full md:w-1/2">
            <ImageWithHoverEffect
              image={Plan3}
              overlayTitle="4.16 Marla "
              overlaySubTitle="Ground Floor Plan for 4.16 Marla house."
              onClick={() => openModal(plansData[0])}
            />
          </div>
        </section>

        {/* Plan 5 Marla */}
        <section className="flex flex-col md:flex-row md:space-x-6 space-y-6 md:space-y-0 p-4">
          <div className="w-full md:w-1/2 flex flex-col items-start text-left justify-center">
            <ul className="mb-3">
              <li>Design your Home</li>
              <li>Choose your own floor plan</li>
              <li>
                Stay updated with the process of construction on the web portal
              </li>
              <li>
                List of all the vendors supplying materials will be available on
                the web portal
              </li>
            </ul>
            <ArrowLabel
              label="5 Marla "
              color="black"
              onClick={() => openModal(plansData[1])}
            />
          </div>

          <div className="w-full md:w-1/2">
            <VideoWithHoverEffect
              videoSrc={plansData[1].videoSrc}
              overlayTitle="5 Marla "
              overlaySubTitle="Ground Floor and First Floor Plan for 5 Marla house."
              onClick={() => openModal(plansData[1])}
            />
          </div>
        </section>

        {/* Plan 10 Marla */}
        <section className="flex flex-col md:flex-row md:space-x-6 space-y-6 md:space-y-0 p-4">
          <div className="w-full md:w-1/2 flex flex-col items-start text-left justify-center">
            <ul className="mb-3">
              <li>Design your Home</li>
              <li>Choose your own floor plan</li>
              <li>
                Stay updated with the process of construction on the web portal
              </li>
              <li>
                List of all the vendors supplying materials will be available on
                the web portal
              </li>
            </ul>
            <ArrowLabel
              label="10 Marla"
              color="black"
              onClick={() => openModal(plansData[2])}
            />
          </div>

          <div className="w-full md:w-1/2">
            <ImageWithHoverEffect
              image={Plan16Marla}
              overlayTitle="10 Marla Villa"
              overlaySubTitle="Ground Floor, First Floor, and Mumty Plan for 10 Marla house."
              onClick={() => openModal(plansData[2])}
            />
          </div>
        </section>
      </section>

      {/* Modal */}
      {isModalOpen && modalData && (
        <PlanModal
          planImage={modalData.planImage}
          videoSrc={modalData.videoSrc}
          planName={modalData.planName}
          total={modalData.total}
          advance={modalData.advance}
          installment={modalData.installment}
          afterNinMonths={modalData.afterNinMonths}
          Confirmation={modalData.Confirmation}
          onClose={closeModal}
        />
      )}
    </div>
  );
};

export default HousingPlan;
