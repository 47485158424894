import React from "react";

const ArrowLabel = ({ direction = "right", label, href, color, onClick }) => {
  const textColor = color === "black" ? "text-background" : "text-white";

  // Conditionally render <a> or <span> based on the presence of `href`
  const Wrapper = href ? "a" : "span";
  const wrapperProps = {
    ...(href ? { href } : {}),
    ...(onClick ? { onClick } : {}),
    className: `group inline-flex items-center space-x-2 flex-row justify-center transform rotate-0 ${textColor} font-heading hover:text-textHover cursor-pointer`,
  };

  return (
    <>
      {direction === "right" ? (
        <Wrapper {...wrapperProps}>
          <span className="text-2xl xl:text-3xl font-heading">{label}</span>
          <svg
            width="36"
            height="10"
            className={`transition-transform duration-300 group-hover:translate-x-1 sm:group-hover:translate-x-2 mt-1`}
          >
            <path
              d="M 0,5 H 36 L 30,0 M 36,5 L 30,10"
              fill="none"
              stroke="#e67e22"
              strokeWidth="2"
            />
          </svg>
        </Wrapper>
      ) : direction === "left" ? (
        <Wrapper {...wrapperProps}>
          <svg
            width="36"
            height="10"
            className={`transition-transform duration-300 group-hover:-translate-x-1 sm:group-hover:-translate-x-2`}
          >
            <path
              d="M 36,5 H 0 L 6,0 M 0,5 L 6,10"
              fill="none"
              stroke="#e67e22"
              strokeWidth="2"
            />
          </svg>
          <span className="text-2xl xl:text-3xl font-heading">{label}</span>
        </Wrapper>
      ) : direction === "up" ? (
        <Wrapper {...wrapperProps} className={`transform -rotate-90`}>
          <span className="text-2xl xl:text-3xl font-heading">{label}</span>
          <svg
            width="36"
            height="10"
            className={`transition-transform duration-300 group-hover:translate-x-1 sm:group-hover:translate-x-2 mt-1`}
          >
            <path
              d="M 0,5 H 36 L 30,0 M 36,5 L 30,10"
              fill="none"
              stroke="#e67e22"
              strokeWidth="2"
            />
          </svg>
        </Wrapper>
      ) : direction === "down" ? (
        <Wrapper {...wrapperProps} className={`transform rotate-90`}>
          <span className="text-2xl xl:text-3xl font-heading">{label}</span>
          <svg
            width="36"
            height="10"
            className={`transition-transform duration-300 group-hover:translate-x-1 sm:group-hover:translate-x-2 mt-1`}
          >
            <path
              d="M 0,5 H 36 L 30,0 M 36,5 L 30,10"
              fill="none"
              stroke="#e67e22"
              strokeWidth="2"
            />
          </svg>
        </Wrapper>
      ) : (
        <span></span>
      )}
    </>
  );
};

export default ArrowLabel;
