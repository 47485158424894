import React, { useState } from "react";

const VideoWithHoverEffect = ({
  videoSrc,
  overlayTitle,
  overlaySubTitle,
  onClick,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="flex flex-col relative items-center">
      <div
        className="relative w-2/3 mb-4 overflow-hidden group cursor-pointer "
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={onClick}
      >
        <video
          className="w-full h-full object-cover"
          src={videoSrc}
          autoPlay
          muted
          loop
          playsInline
        />
        {isHovered && (
          <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center text-white p-4 transition-opacity duration-300">
            <h3 className="text-3xl font-bold mb-2 font-heading">
              {overlayTitle}
            </h3>
            <p className="text-white text-xl font-light font-body">
              {overlaySubTitle}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoWithHoverEffect;
