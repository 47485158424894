import { toast } from "react-toastify";

const SCRIPT_URL =
  "https://script.google.com/macros/s/AKfycbzxdC8Y-mP-xKN7SdK-B_ILVCvN2Hhx3nu3G_LE4fh15J_nvksRYOypfSwHhZi-6TVvCA/exec";

const submitData = async (formData) => {
  try {
    // Create URL-encoded string directly from form data
    const formDataString = new URLSearchParams({
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      plan: formData.plan + " marla",
      message: formData.message,
    }).toString();

    await fetch(SCRIPT_URL, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formDataString,
    });
    if (formData.name !== "") {
      toast.success(
        "Message sent successfully. We'll get back with you shortly!"
      );
    }
  } catch (error) {
    console.error("Form submission error:", error);
    if (formData.name !== "") {
      toast.error("An unexpected error occurred. Please try again later!");
    }
  }
};

export default submitData;