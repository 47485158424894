import React, { useState } from "react";
import submitData from "../utils/contactUsFormSubmission";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    plan: "4.56",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await submitData(formData);
    setFormData({
      name: "",
      email: "",
      phone: "",
      plan: "4.56",
      message: "",
    });
    setIsLoading(false);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const plans = [
    { value: "4.56", label: "4.56 Marla" },
    { value: "5", label: "5 Marla" },
    { value: "10", label: "10 Marla" },
  ];

  return (
    <div className="min-h-screen pt-[150px] bg-gray-900">
      {/* Header Section */}
      <div className="relative pb-10 bg-gradient-to-b from-gray-900 to-gray-800">
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="max-w-6xl mx-auto text-center mb-16">
            <h1 className="text-white text-5xl sm:text-6xl md:text-7xl font-heading mb-4 animate-fade-in-down">
              Contact Us
            </h1>
            <p className="text-white text-xl sm:text-2xl md:text-3xl font-body max-w-3xl mx-auto animate-fade-in-up">
              We're here to help
            </p>
            <p className="text-gray-400 font-body text-sm sm:text-base md:text-lg mb-8 max-w-2xl mx-auto animate-fade-in-up italic">
              Get in touch with us for any questions about our properties
            </p>
          </div>
        </div>
      </div>

      {/* Contact Information Cards */}
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mb-16 mt-10">
        <div className="grid md:grid-cols-3 gap-8">
          {/* Location Card */}
          <div className="bg-gray-800 rounded-lg p-6 text-center shadow-lg hover:shadow-xl transition-shadow duration-300">
            <div className="flex justify-center mb-4">
              <div className="rounded-full bg-gray-700 p-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-textHover"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </div>
            </div>
            <h3 className="font-heading text-xl mb-2 text-white">Visit Us</h3>
            <p className="text-gray-400 font-body">
              Infront of D-18 Roots Millennium School
              <br />
              Islamabad, Pakistan
            </p>
          </div>

          {/* Phone Card */}
          <div className="bg-gray-800 rounded-lg p-6 text-center shadow-lg hover:shadow-xl transition-shadow duration-300">
            <div className="flex justify-center mb-4">
              <div className="rounded-full bg-gray-700 p-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-textHover"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>
              </div>
            </div>
            <h3 className="font-heading text-xl mb-2 text-white">Call Us</h3>
            <p className="text-gray-400 font-body">
              +92 370 5044794
              <br />
              +92 332 1633339
            </p>
          </div>

          {/* Email Card */}
          <div className="bg-gray-800 rounded-lg p-6 text-center shadow-lg hover:shadow-xl transition-shadow duration-300">
            <div className="flex justify-center mb-4">
              <div className="rounded-full bg-gray-700 p-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-textHover"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
              </div>
            </div>
            <h3 className="font-heading text-xl mb-2 text-white">Email Us</h3>
            <p className="text-gray-400 font-body">
              info@hikshomes.com
            </p>
          </div>
        </div>
      </div>

      {/* Contact Form Section */}
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pb-20">
        <div className="bg-gray-800 rounded-lg p-8 shadow-lg">
          <h2 className="text-2xl font-heading text-white text-center mb-8">
            Send us a Message
          </h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid md:grid-cols-2 gap-6">
              <div>
                <input
                  type="text"
                  name="name"
                  placeholder="Your Name"
                  className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-textHover focus:border-transparent"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div>
                <input
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-textHover focus:border-transparent"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div>
              <input
                type="tel"
                name="phone"
                placeholder="Phone Number"
                className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-textHover focus:border-transparent"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>
            <div>
              {/* Plan Selection */}
              <div className="space-y-3 mb-5">
                <label className="block text-white text-lg mb-2 font-heading ">
                  Select Plan Size:
                </label>
                <div className="grid md:grid-cols-3 justify-center">
                  {plans.map((plan) => (
                    <div key={plan.value} className="flex items-center md:justify-center">
                      <input
                        type="radio"
                        id={`plan-${plan.value}`}
                        name="plan"
                        value={plan.value}
                        checked={formData.plan === plan.value}
                        onChange={handleChange}
                        className="w-4 h-4 text-textHover bg-gray-700 border-gray-600 focus:ring-textHover focus:ring-2"
                      />
                      <label
                        htmlFor={`plan-${plan.value}`}
                        className="ml-2 text-white font-body"
                      >
                        {plan.label}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <textarea
                name="message"
                placeholder="Your Message"
                rows={6}
                className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-textHover focus:border-transparent"
                value={formData.message}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="bg-textHover hover:bg-textHover/80 text-white px-8 py-3 rounded-md transition-colors duration-300 font-medium"
              >
                {
                  isLoading? (
                    <div className="loader border-2 border-t-2 border-t-transparent border-secondary w-5 h-5 rounded-full animate-spin" />
                  ) : (
                    <span>Send Message</span>
                  )
                }
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
