import React from "react";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import ArrowLabel from "../components/ArrowLabel";

const GetInTouch = () => {
  return (
    <div className="bg-light-peach py-16  my-5 mx-28 border border-gray-300 rounded-sm">
      <div className="max-w-4xl mx-auto text-center">
        <h2 className="text-4xl  text-gray-800 font-heading">Get in touch</h2>
        <p className="mt-4 text-xl font-medium text-gray-700 font-body">
          We'd love to hear from you
        </p>
        <p className="mt-2 text-base text-gray-600 font-body">
          Get in touch to find out more or just ask us a question
        </p>
        <div className="mt-8">
          <ArrowLabel
            direction="right"
            label="Contact"
            color="black"
            className="text-lg hover:translate-x-2 transition-transform duration-300"
            href="contact-us"
          />
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
