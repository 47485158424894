import React from "react";
import { HiOutlineArrowLongDown } from "react-icons/hi2";

const CustomHeading = ({ heading, description }) => {
  return (
    <div className="flex flex-col items-center text-center pb-12 pt-[150px] bg-background text-white">
      <h2 className="text-5xl font-heading">{heading}</h2>
      <p className="mt-4 text-lg font-body text-customGray w-1/2 ">
        {description}
      </p>
      <div className="mt-6 flex justify-center">
        <HiOutlineArrowLongDown size={55} color="#e67e22" />
      </div>
    </div>
  );
};

export default CustomHeading;
