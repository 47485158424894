import React from "react";

const LocationMap = () => {
  return (
    <div className="p-4 sm:p-6 border border-customGray rounded-lg">
      <h3 className="text-3xl text-white sm:text-4xl  text-center my-5 font-heading">
        Location on Map
      </h3>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d5466.38277746022!2d72.8539507!3d33.6481827!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDM4JzUzLjYiTiA3MsKwNTEnMTUuMiJF!5e1!3m2!1sen!2s!4v1729689923750!5m2!1sen!2s"
        width="100%"
        height="450"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        className="rounded-lg"
      ></iframe>
    </div>
  );
};

export default LocationMap;
